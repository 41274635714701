import { motion } from 'framer-motion';
import React from 'react';
import { useAppointmentContext } from 'src/context/AppointmentContext';
import { useThemeContext } from 'src/context/ThemeContext';

const PatientTypeToggle = () => {
    const { 
        isExistingPatient, 
        setIsExistingPatient,
        isNewConcern,
        setIsNewConcern
    } = useAppointmentContext();
    const { secondaryColor } = useThemeContext();

    const selectedStyle = {
        backgroundColor: secondaryColor,
        color: 'white',
        borderColor: 'white',
        transform: 'scale(1.1)'
    }

    const handleChange = (step) => {
        if(step === 'patientType') {
            setIsExistingPatient(null);
        }
        setIsNewConcern(null);
    }

    return (
        <div>
            <div className='toggle-title'>Have you been to this clinic before?</div>
            {isExistingPatient == null ? <div className='patient-type-select'>
                <div 
                    className='patient-type' 
                    style={(isExistingPatient != null && !isExistingPatient) ? selectedStyle : {}}
                    onClick={() => setIsExistingPatient(false)}   
                >
                    I am a new patient
                </div>
                <div 
                    className='patient-type' 
                    style={(isExistingPatient != null && isExistingPatient) ? selectedStyle : {}}
                    onClick={() => setIsExistingPatient(true)}   
                >
                    I am an existing patient
                </div>
            </div> : 
            <div className='d-flex-center selected-desc'>
                {isExistingPatient != null && <div className='d-flex-center'>
                    <div className='selected-desc light-text'>{isExistingPatient ? 'I am an existing patient' : 'I am a new patient'}</div>
                    <div className='selected-change' style={{color: secondaryColor}} onClick={() => handleChange('patientType')}>Change</div>
                </div>}
            </div>
            }
            {isExistingPatient && 
                <motion.div 
                    animate={{ height: 'auto' }} 
                    initial={{ height: 0 }} 
                    transition={{ ease: "easeInOut", duration: 0.3 }} 
                    style={{paddingTop: '20px', overflow: 'hidden'}}
                >
                    <div className='toggle-title'>Is this a new concern?</div>
                    {isNewConcern == null ? <div className='d-flex-center'>
                        <div 
                            className='concern-option' 
                            style={(isNewConcern != null && isNewConcern) ? selectedStyle : {}}
                            onClick={() => setIsNewConcern(true)}   
                        >
                            Yes
                        </div>
                        <div 
                            className='concern-option' 
                            style={(isNewConcern != null && !isNewConcern) ? selectedStyle : {}}
                            onClick={() => setIsNewConcern(false)}   
                        >
                            No
                        </div>
                    </div> : 
                    <div className='d-flex-center selected-desc'>
                        {isNewConcern != null && <div className='d-flex-center'>
                            <div className='selected-desc light-text'>{isNewConcern ? 'Yes' : 'No'}</div>
                            <div className='selected-change' style={{color: secondaryColor}} onClick={() => handleChange('newConcern')}>Change</div>
                        </div>}
                    </div>}
                </motion.div>   
            }
        </div>
    )
}

export default PatientTypeToggle;