import moment from 'moment';

export const optomate = {
    getAppointmentTypes: async function (identifier) {
        let types = await this.apiFetch("OnlineAppointmentTypes");
        types = types.value;

        let processedTypes = [];

        Object.keys(types).forEach(function(k){     
            processedTypes.push({
                ID: types[k].IDENTIFIER,
                numerical_id: types[k].ID,
                description: types[k].DESCRIPTION.replace('(Online)', '').replace('INITIAL CONSULTATION', 'Initial Consultation'), 
                duration: types[k].DEFAULT_DURATION
            })
        });
        return processedTypes;
    },
    getPractioners: async function (identifier) {

        let practioner = await this.apiFetch("Optometrists?$filter=BRANCH_IDENTIFIER eq 'WAT'&$expand=APP_SETUPS");
        practioner = practioner.value;

        let filter = "";
        practioner.forEach((practioner) => { filter += "OPTOMETRIST_ID eq " + practioner.ID + " or " });
        filter = filter.slice(0, -4);
        filter = "(" + filter + ") and BRANCH_IDENTIFIER eq '" + identifier + "' and STARTDATETIME gt " + moment().subtract(1, 'day').format("YYYY-MM-DD");

        let appointments = await this.getAppointments(filter);

        let processedPractioners = [];

        Object.keys(practioner).forEach(function(k){
            let setup = {};
            for (let i = 0; i < practioner[k].APP_SETUPS.length; i++) {
                if (practioner[k].APP_SETUPS[i].BRANCH_IDENTIFIER === identifier) {               
                    setup = practioner[k].APP_SETUPS[i];                 
                }
            }

            let currentPractitionerAppointments = [];
            for (let i = 0; i < appointments.length; i++) {
                if (practioner[k].ID === appointments[i].practitioner_id) {               
                    currentPractitionerAppointments.push(appointments[i]);
                }
            }

            var times = [];
            let first = "";
            for (let i = 0; i < 7; i++) {
                let startDate = moment().add(i, 'days');             
                let day = startDate.format('dddd').substring(0,3).toUpperCase();
                if(day === "THU") day = "THR";

                if(setup[day+'ACTIVE']) {
                    let remainder = setup.DEFAULT_DURATION - (moment().minute() % setup.DEFAULT_DURATION);
                    let startTime = i === 0 ? moment().add(remainder, 'm').format("hh:mm A") : setup[day+'START'];
                    var startDateTime = moment(startDate.format("DD-MM-YYYY") +" "+ startTime, "DD-MM-YYYY hh:mm A");
                    var endDateTime = moment(startDate.format("DD-MM-YYYY") +" "+ setup[day+'FIN'], "DD-MM-YYYY hh:mm A");

                    while(startDateTime.isBefore(endDateTime.format('YYYY-MM-DD HH:mm')) && startDateTime.isAfter(moment(), 'm')) {
                        if(!first) first = startDateTime.format('YYYY-MM-DD HH:mm');
                        times.push(startDateTime.format('YYYY-MM-DD HH:mm'));
                        startDateTime.add(setup.DEFAULT_DURATION, 'm');
                    }    
                }                              
            }

            processedPractioners.push({
              ID: practioner[k].ID,
              firstName: practioner[k].GIVEN_NAME,
              lastName: practioner[k].SURNAME,
              active: (practioner[k].INACTIVE && !times.length > 0) ? false : true,
              availabilities: [],
              availabilitiesAll: times,
              selectedDateAvailabilities: [],
              appointments: currentPractitionerAppointments,
              appSetup: setup,
              imgSrc: 'https://api.backinmotion.com.au/practitioners/photos/' + practioner[k].ID + '.png',
              firstAvailable: first
            });
        });
        return processedPractioners;
    },
    getAppointments: async function (filter) {

        let appointments = await this.apiFetch("Appointments?$filter=" + filter);
        let processedAppointments = []
        
        appointments = appointments.value;
        Object.keys(appointments).forEach(function(k){
            processedAppointments.push({
                start: moment(appointments[k].STARTDATETIME.replace(':00+11:00', '').replace('T', ' ')).format('YYYY-MM-DD HH:mm'),
                duration: appointments[k].DURATION,
                practitioner_id: appointments[k].OPTOMETRIST_ID
            });
        })

        return processedAppointments;
    },
    getLocationData: async function (identifier) {
        let branches = await this.apiFetch("Branches");
        let currentBranch = {};
        branches.value.forEach((branch) => {
            if(branch.IDENTIFIER === identifier) currentBranch = branch;
        });
        return {
            name: currentBranch.NAME,
            address: currentBranch.ADDRESS,
            suburb: currentBranch.SUBURB,
            phone: currentBranch.PHONE,
            postcode: currentBranch.POSTCODE,
            email: currentBranch.EMAIL,
            state: currentBranch.STATE,
            bulkbill: true,
        };
    },
    apiFetch: async function(modifiers, body=[]) {
        let params = new URLSearchParams(window.location.search);
        let sessionToken = params.get('session_token');

        var fetchURL = `/optomate/data/get?session_token=${sessionToken}&provider=optomate`;
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Basic T05MSU5FQk9PS0lORzoxMjM0`);

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            modifiers: modifiers,
            body: body,
        }),
        };
      
        const data = fetch(fetchURL, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            if(process.env.NODE_ENV === 'development') console.log(result);
            return result;
        })
        .catch(error => console.log('error', error, "\n" + fetchURL));
      
      return data;
    },
    postAppointment: async function(details) {   
        let patientID = await this.checkPatient(details);

        let appointment = await this.apiFetch(
            "Appointment",
            JSON.stringify({
                STARTDATE: details.startdate,
                STARTTIME: details.starttime,
                BRANCH_IDENTIFIER: details.branchIdentifier,
                OPTOMETRIST_ID: details.practitionerID,
                APPOINTMENT_TYPE: details.appointmentType,
                PATIENT_ID: patientID
            })
        );

        return appointment.ID ? true : false;
    },
    checkPractitioner: function(practitioner, currentService) {
        let bookingStepDuration = practitioner.appSetup.DEFAULT_DURATION;
        practitioner.availabilities = practitioner.availabilitiesAll.slice(0);
        for (let i = 0; i < practitioner.appointments.length; i++) {
            let index = practitioner.availabilities.indexOf(practitioner.appointments[i].start);
            // Go back until you can fit the appointment in before the next booking
            let indexOfFirstTime = index-currentService.duration/bookingStepDuration+1;
            if(index > -1) {
                practitioner.availabilities.splice(
                    indexOfFirstTime, 
                    Math.ceil(practitioner.appointments[i].duration/bookingStepDuration)
                );
            }
        }

        for (let i = 0; i < 7; i++) {

            let startDate = moment().add(i, 'days');       
            let day = startDate.format('dddd').substring(0,3).toUpperCase();
            if(day === "THU") day = "THR";

            let endDayTimes = [];
            if(practitioner.appSetup[day+'ACTIVE']) {
                for (let index = 0; index < currentService.duration/bookingStepDuration; index++) {
                    endDayTimes.push(moment(startDate.format("DD-MM-YYYY") +" "+ practitioner.appSetup[day+'FIN'], "DD-MM-YYYY hh:mm A").subtract(bookingStepDuration * index, 'm'));
                }
            }
            
            for (let j = 0; j < endDayTimes.length; j++) {
                let index = practitioner.availabilities.indexOf(endDayTimes[j].format('YYYY-MM-DD HH:mm'));
                if(index > -1) {
                    practitioner.availabilities.splice(index, 1);
                }
            }
        }
        return practitioner;
    },
    checkPatient: async function(details) {

        let patient = await this.apiFetch(
            "Patients/SensitiveSearch",
            JSON.stringify({
                "Search": {
                    "And":[
                        {"PropertyName": "GIVEN", "StringPredicate" : { "EqualsVal" : details.bookingDetails.firstName }},
                        {"PropertyName": "SURNAME", "StringPredicate" : { "EqualsVal" : details.bookingDetails.lastName }},
                        {"Or": [
                            {"PropertyName": "MOBILE_PHONE", "StringPredicate" : { "EqualsVal" : String(details.bookingDetails.phone).replace(/\s/g, '') }},
                            {"PropertyName": "EMAIL", "StringPredicate" : { "EqualsVal" : details.bookingDetails.email }}
                        ]}
                    ]
                }
            })
        );
        
        if(patient.value[0]) {
            return patient.value[0].ID;
        } else {
            let patient = await this.apiFetch(
                "Patient",
                JSON.stringify({
                    GIVEN: details.bookingDetails.firstName,
                    SURNAME: details.bookingDetails.lastName,
                    OPTOMETRIST_ID: details.practitionerID,
                    EMAIL: details.bookingDetails.email,
                    MOBILE_PHONE: details.bookingDetails.phone,
                    HEALTHFUND_IDENTIFIER: "AHM",
                    BRANCH_IDENTIFIER: details.branchIdentifier,
                    GENDER: details.bookingDetails.gender.substring(0,1),
                    BIRTHDATE: moment(details.bookingDetails.DOB.$d).format('YYYY-MM-DD')
                })
            )
            return patient.ID;
        }
    },
    getTypeExceptions: async function() {
        return [];
    },
    getCategoryOrder: async function() {
        return [];
    },
    sendConfirmMessage: async function (message) {
        await fetch("/optomate/notification/sendConfirmation" + message, {method: 'GET'});
    },
    getFormFields: function() { 
        return [
            {name: 'visited', label: 'Have you visited this practice before?', required: true, options: ["Yes", "No"], type: 'radio', size: 12},
            {name: 'firstName', label: 'First Name', required: true, size: 6},
            {name: 'lastName', label: 'Last Name', required: true, size: 6},
            {name: 'gender', label: 'Gender', required: true, options: ["Male", "Female", "Other"], type: 'radio', size: 6},
            {name: 'DOB', label: 'Date of Birth', required: true, size: 6, type: 'date'},
            {name: 'phone', label: 'Phone Number', required: true, size: 6},
            {name: 'email', label: 'Email', required: true, size: 6}
        ]
    },
    async checkConnection() {
        let conn = await this.apiFetch("Branches");
        return Array.isArray(conn.value);
    }
}