import React, { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import AppointmentList from 'src/components/AppointmentList';
import AppointmentListHeader from 'src/components/AppointmentListHeader';
import { useGlobalContext } from 'src/context/GlobalContext';
import TypeSelect from 'src/components/TypeSelect';
import PersonIcon from '@mui/icons-material/Person';
import PatientTypeToggle from 'src/components/PatientTypeToggle';
import { motion } from 'framer-motion';
import { useThemeContext } from 'src/context/ThemeContext';
import Notification from 'src/components/Notification';

const TypeSelectView = () => {

  const { 
    selectedType, 
    setSelectedType,
    practitioners,
    windowMovedBack,
    branch,
    bookingExists,
    setBookingExists
  } = useGlobalContext();

  const { secondaryColor } = useThemeContext();

  const [onChangeCallback, setOnChangeCallback] = useState(null);

  const handleChange = () => {
    setSelectedType({ID: ""});
    if(onChangeCallback) {
      onChangeCallback();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if(bookingExists) setBookingExists(false);
    }, 11000);
  }, [bookingExists, setBookingExists]);

  return (
    <div className='view-wrapper' style={{position: "relative"}}>

      {bookingExists && <Notification message={"There is an exisiting appointment at this time, please choose another time slot"}/>}

      <div className='d-flex-center'>
        <PersonIcon sx={{fontSize: '50px', marginTop: '15px', marginRight: '10px'}}/> 
        <div className='booking-title'>Book an Appointment</div>
      </div>

      <motion.div 
        animate={{ height: (selectedType.ID) ? 0 : 'auto' }} 
        initial={{ height: windowMovedBack ? 0 : 'auto' }} 
        transition={{ ease: "easeInOut", duration: 0.5 }} 
        style={{overflow: 'hidden', position: 'relative'}}
      >
        {selectedType.ID && <motion.div className='overlay'></motion.div>}
        {branch && branch.client.useCategory && <PatientTypeToggle/>}
        <TypeSelect setCallback={setOnChangeCallback}/>
      </motion.div>

      {selectedType.ID && <div className='d-flex-center selected-appointment-type' style={{marginBottom: '20px'}}>
        <div className='selected-desc'>{selectedType.description}</div>
        <div className='selected-change' style={{color: secondaryColor}} onClick={handleChange}>Change</div>
      </div>}

      <motion.div 
        animate={{ height: (selectedType.ID) ? 'auto' : 0 }} 
        initial={{ height: windowMovedBack ? 'auto' : 0 }} 
        transition={{ ease: "easeInOut", duration: 0.3, delay: 1 }} 
        style={{ overflow: 'hidden'}}
      >
        {selectedType.ID && practitioners.length > 0 && <AppointmentListHeader/>}
        {selectedType.ID && practitioners.length > 0 && <AppointmentList/>}
        {selectedType.ID && !practitioners.length > 0 && <LinearProgress 
          sx={{ backgroundColor: 'lightgrey', '& .MuiLinearProgress-bar': { backgroundColor: secondaryColor } }}
        />}
      </motion.div>

    </div>
  );
} 

export default TypeSelectView;