import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useAppointmentContext } from 'src/context/AppointmentContext';
import { Grid, Skeleton } from '@mui/material';
import { motion } from "framer-motion";
import { ArrowRight, ArrowLeft } from '@mui/icons-material';
import { useGlobalContext } from 'src/context/GlobalContext';
import { useThemeContext } from 'src/context/ThemeContext';

const Calender = ({practitioner}) => {

    const { 
        selectedType, 
        bookingDetails, 
        setBookingDetails,
        handleWindowChange,
        isMobile
    } = useGlobalContext();

    const { secondaryColor } = useThemeContext();

    const [openPractitioner, setOpenPractitioner] = useState(null);
    const [appointmentTimes, setAppointmentTimes] = useState([]);
    const [hasOverflowDates, setHasOverflowDates] = useState(false);
    const [maxRows, setMaxRows] = useState(0);
    const [loading, setLoading] = useState(true);
    const [nextAvailable, setNextAvailable] = useState(moment());
    const {startingDate, setStartingDate } = useAppointmentContext();

    // Number of rows to display in the calendar
    const numRows = 7;
    // Number of columns to display in the calendar
    const numCols = 5;

    useEffect(() => {
        const updateAppointments = async () => {

            setOpenPractitioner(null);
            const appointmentTimes = [];
            let maxRows = 0;
            let hasOverFlow = false;
    
            // Start processing appointments
            for (let i = 0; i < numCols; i++) {
                appointmentTimes.push([]);
                const currentAvailabilities = practitioner.availabilities.filter(availability =>
                    moment(availability, 'YYYY-MM-DD HH:mm').isSame(moment(startingDate).add(i, 'days'), 'day')
                );
                hasOverFlow = hasOverFlow || currentAvailabilities.length > numRows;
                maxRows = Math.max(maxRows, currentAvailabilities.length);
    
                for (let j = 0; j < (currentAvailabilities.length > numRows ? currentAvailabilities.length : numRows); j++) {
                    if (currentAvailabilities[j]) {
                        appointmentTimes[i].push(currentAvailabilities[j]);
                    } else {
                        appointmentTimes[i].push('');
                    }
                }
            }
    
            // Update states after processing
            setAppointmentTimes(appointmentTimes);
            setHasOverflowDates(hasOverFlow);
            setMaxRows(maxRows);

            if(maxRows === 0) {
                setNextAvailable(moment(practitioner.availabilities[0], 'YYYY-MM-DD HH:mm'));
            }

        };
        
        setLoading(true);
        setTimeout (async() => { 
            await updateAppointments().then(() => setLoading(false));
        },
        300);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startingDate, selectedType]);

    const handleBookingTimeClicked = (time, practitioner) => {
        setBookingDetails({
          ...bookingDetails,
          'doctorID': practitioner.ID,
          'dateTime': time,
          'duration': selectedType.duration,
          'doctorName': practitioner.firstName + ' ' + practitioner.lastName,
          'imgSrc': practitioner.imgSrc,
          'appointmentType': selectedType.ID,
          'appointmentTypeDesc': selectedType.description,
          'address': "123 St, Place, 3032"
        });
        handleWindowChange(true);
    }

    const handleNextAvailable = async() => {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 500)); // Give time for the loading state to render
        setStartingDate(nextAvailable)
    }

    return (         
        <div>
            {loading ? 
                <div style={{height: numRows * 31}}>
                    <Grid container>
                        {!isMobile && <Grid item xs={1}></Grid>}
                        <Grid item xs={12} md={10}>
                            <Skeleton animation='pulse' variant="rectangular" width={'100%'} height={(numRows * 31) + 1} />
                        </Grid>
                        {!isMobile && <Grid item xs={1}></Grid>}
                    </Grid>
                </div> 
                : 
                <div>
                    <motion.div 
                        animate={{ height: openPractitioner === practitioner.ID ? `${maxRows * 31 - 1}px` : numRows * 31 }} 
                        initial={{ height: numRows * 31 }} 
                        transition={{ ease: "easeInOut", duration: 0.5, delay: 0.5 }} 
                        style={{overflow: 'hidden', position: "relative"}}
                    >
                        <Grid container>
                            {!maxRows && <div className='next-available' style={{backgroundColor: secondaryColor}} onClick={handleNextAvailable}>
                                {moment(nextAvailable).diff(moment(startingDate)) < 0 && <ArrowLeft/>}
                                <span style={{paddingTop: '5px'}}>
                                    {moment(nextAvailable).diff(moment(startingDate)) >= 0 ? `Next Availability in ${moment(nextAvailable).diff(moment(startingDate), 'days')} days` : 'Previous Availability'}
                                </span>                
                                {moment(nextAvailable).diff(moment(startingDate)) > 0 && <ArrowRight/>}
                            </div>}
                            {!isMobile && <Grid item xs={1}></Grid>}
                            {appointmentTimes.map((times, i) => {
                                return <Grid className='date-column' item xs={12/5} md={2} key={i} sx={{backgroundColor: i % 2 === 0 ? '#f4f4f4' : '#fff'}}>
                                    {times.map((time, j) => {
                                        return (
                                            <div key={j} 
                                                style={{
                                                    cursor: time === '' ? '' : 'pointer',
                                                }}
                                                className={`time-wrapper${time ? '' : '-empty'}`} 
                                                onClick={time !== '' ? () => handleBookingTimeClicked(moment(time), practitioner) : undefined}>
                                                <div className='timeslot'>                                        
                                                    {time && moment(time).format('h:mm a')}
                                                </div>           
                                            </div>
                                        )
                                    })}
                                </Grid>
                            })}
                            {!isMobile &&<Grid item xs={1}></Grid>}
                        </Grid>
                    </motion.div>
                    {hasOverflowDates && 
                        <div className='more-times' 
                            onClick={() => setOpenPractitioner(openPractitioner === practitioner.ID ? null : practitioner.ID)}>
                            {openPractitioner === practitioner.ID ? 'Less Times' : 'More times' }
                        </div>
                    }
                </div>
            }
        </div> 
    )
}

export default Calender;