import moment from "moment";

export const nookal = {
    getAppointmentTypes: async function () {

        let services = await this.apiFetch("getAppointmentTypes", true);

        let categories = await this.apiFetch(
            `{"query":"{services(serviceIDs:[${services.appointmentTypes.map((service) => service.ID).join(",")}]) {serviceID, category, description}}"}`
        );
        categories = categories.data.services;

        const formattedServices =  services.appointmentTypes.map((service) => {
            return {
                ID: service.ID,
                duration: service.Duration,
                description: service.Description,
                providers: JSON.parse(service.Providers),
                category: categories.find((category) => category.serviceID === parseInt(service.ID)).category,
            };
        });
        
        return formattedServices;
    },
    getPractioners: async function (identifier) {

        let convertedPractitioners = [];

        var practitioner = await this.apiFetch("getPractitioners?");
        practitioner = practitioner.practitioners;

        let practitionerBios = await this.apiFetch(`{"query":"{staff(locationIDs:[${identifier}]) {staffID, onlineBiography}}"}`);

        Object.keys(practitioner).forEach(function(k){
            
            if(practitioner[k].locations.includes(parseInt(identifier))
                || practitioner[k].locations.includes(identifier)
                ) {
                convertedPractitioners.push({
                    ID: practitioner[k].ID,
                    firstName: practitioner[k].FirstName,
                    lastName: practitioner[k].LastName,
                    active: true,
                    speciality: practitioner[k].Speciality,
                    availabilities: [],
                    availabilitiesList: [],
                    services: [],
                    email: practitioner[k].Email,
                    biography: practitionerBios.data.staff.find((bio) => bio.staffID === parseInt(practitioner[k].ID))?.onlineBiography,
                });
            }
        });

        await Promise.all(convertedPractitioners.map(async(practitioner)=>{

            var photo = await this.apiFetch("getPractitionerPhoto?practitioner_id=" + practitioner.ID);
            practitioner.imgSrc = photo ? photo.url : '/static/images/optometrist/Account.png';

            var getServices = await this.apiFetch("getPractitionerAppointmentTypes?"
                + "&practitioner_id=" + practitioner.ID
                + "&location_id=" + identifier
            );
            practitioner.services = getServices.services;

            var getTimes = await this.apiFetch("getAppointmentAvailabilities?"
                + "&practitioner_id=" + practitioner.ID
                + "&location_id=" + identifier
                + "&date_from=" + moment().format("YYYY-MM-DD")
                + "&date_to=" + moment().add(6, 'months').format("YYYY-MM-DD"));
            practitioner.availabilitiesList = getTimes.availabilities;
            
        }));

        return convertedPractitioners;
    },
    getLocationData: async function (identifier) {
        var result = await this.apiFetch("getLocations?");
        let data = false;

        if(result && result.locations) {
            result = result.locations
            result.forEach((location) => {
                let addressLine = "";
                if(location.AddressLine1) {
                    addressLine += location.AddressLine1;
                    if(location.AddressLine2 || location.AddressLine3) addressLine += ", ";
                }
                if(location.AddressLine2) {
                    addressLine += location.AddressLine2;
                    if(location.AddressLine3) addressLine += ", ";
                }
                if(location.AddressLine3) addressLine += location.AddressLine3;
                if(location.ID === identifier) {
                    let locationData = {
                        name: location.Name,
                        address: addressLine,
                        phone: location.Telephone,
                        postcode: location.Postcode,
                        email: location.Email,
                        state: location.State,
                        bulkbill: false,
                        id: identifier
                    }
                    data = locationData;
                }
            });    
        }
        
        return data;
    },
    checkPractitioner: function(practitioner, currentService) {
        practitioner.availabilities = [];
        Object.keys(practitioner.availabilitiesList).forEach(function (k) {
            if(Object.keys(practitioner.availabilitiesList[k])[1] !== undefined) {
                let timeIncrement = parseInt(Object.keys(practitioner.availabilitiesList[k])[1].split(":")[1]);
                let hour = "00";
                let minute = "00";
                
                let timesToCheck = currentService.duration/10;
                let validCount = 0;
                let timeToAdd = "";
                while(practitioner.availabilitiesList[k][hour + ":" + minute]) {

                    if(practitioner.availabilitiesList[k][hour + ":" + minute] === "available") {
                        if(validCount === 0) timeToAdd = moment(k + " " + hour + ":" + minute).format('YYYY-MM-DD HH:mm');
                        validCount++;
                    } else {
                        validCount = 0;
                    }
                    
                    if(validCount === timesToCheck) {
                        if(moment(k + " " + hour + ":" + minute).isAfter(moment().add(30, 'm'))) {
                            practitioner.availabilities.push(timeToAdd);
                            if(!practitioner.firstAvailable) practitioner.firstAvailable = timeToAdd;
                        }
                        validCount = 0;
                    }
                    minute = (parseInt(minute) + timeIncrement)
                    if(minute >= 60) {
                        minute = "00";
                        hour = parseInt(hour)+1;
                        if(hour < 10) hour = "0" + hour;
                    }
                    else minute = minute.toString();
                }
            }
        });
        return practitioner;
    },
    checkPatient: async function(details) {

        let patientDetails = "first_name=" + details.firstName
        + "&last_name=" + details.lastName
        + "&email=" + details.email
        + "&date_of_birth=" + moment(details.DOB).format("YYYY-MM-DD");

        let patient = await this.apiFetch("searchPatients?" + patientDetails);

        if(patient.patients.length === 0) {

            let extendedPatientDetails = patientDetails 
            + "&email=" + details.email
            + "&phone=" + details.phone
            + "&gender=" + details.gender;

            let createdPatient = await this.apiFetch("addPatient?" + extendedPatientDetails, true);
            return createdPatient.data.results.patient_id;
        } else {
            return patient.patients[0].ID;
        }
    },
    getTypeExceptions: async function() {
        const exceptions = await this.apiFetch("getAppointmentTypeExceptions", true);
        return Array.isArray(exceptions) ? exceptions : [];
    },
    getCategoryOrder: async function() {
        const categories = await this.apiFetch("getCategoryOrder", true);
        return Array.isArray(categories) ? categories : [];
    },
    apiFetch: async function(modifiers, onlyResults=false) {
        let params = new URLSearchParams(window.location.search);
        // set static session token for development
        let sessionToken = process.env.NODE_ENV === 'development' ? process.env.NODE_ENV : params.get('session_token');

        var fetchURL = `/nookal/data/get?session_token=${sessionToken}&provider=nookal`;
      
        var requestOptions = {
          method: 'POST',
          body: JSON.stringify({modifiers: modifiers}),
        };
      
        const data = await fetch(fetchURL, requestOptions)
          .then((response) => response.json())
          .then((result) => {

            if(!result) {
                if(process.env.NODE_ENV === 'development') console.log("No data returned");
                return false;
            }

            if(result.status === 'error') {
                console.log(result.message);
                return false;
            }

            if(onlyResults) {
                if(process.env.NODE_ENV === 'development') console.log(result);
                return result;
            }

            if(result.data) {
                if(process.env.NODE_ENV === 'development') console.log(result.data.results);
                return result.data.results;
            } else {
                if(process.env.NODE_ENV === 'development') console.log(result.details.alerts ? result.details.alerts : result.status+":", result.details.errorMessage);
                if(process.env.NODE_ENV === 'development') console.log("Modifiers:", modifiers);
                return false;
            }

          })
          .catch(error => console.log('error', error));

        return data;
    },
    postAppointment: async function (details) {
        details.patientID = await this.checkPatient(details.bookingDetails);

        let modifier = "&location_id=" + details.branchIdentifier
        + "&appointment_date=" + details.startdate 
        + "&start_time=" + details.starttime 
        + "&patient_id=" + details.patientID
        + "&practitioner_id=" + details.practitionerID 
        + "&appointment_type_id=" + details.appointmentType
        + "&notes=" + encodeURIComponent(details.notes);

        let booking = await this.apiFetch("addAppointmentBooking?" + modifier, true);
        return booking;
    },
    sendConfirmMessage: async function (message) {
        await fetch("/nookal/notification/sendConfirmation" + message, {method: 'GET'});
    },
    getFormFields: function() { 
        return [
            {name: 'firstName', label: 'First Name', required: true, size: 6, defaultValue: ''},
            {name: 'lastName', label: 'Last Name', required: true, size: 6, defaultValue: ''},
            // {name: 'gender', label: 'Gender', required: true, options: ["Male", "Female", "Other"], type: 'radio', size: 6},
            {name: 'DOB', label: 'Date of Birth', required: true, size: 6, type: 'date', defaultValue: moment()},
            {name: 'phone', label: 'Phone Number', required: true, size: 6, defaultValue: ''},
            {name: 'email', label: 'Email', required: true, size: 6, defaultValue: ''},
            {name: 'notes', label: 'Notes', required: false, size: 12, type: 'textarea', defaultValue: ''},
        ]
    },
    checkConnection: async function() {
        return await this.apiFetch("getLocations?");
    }
}