import React from 'react';
import { Grid } from '@mui/material';
import { useThemeContext } from 'src/context/ThemeContext';
import { useGlobalContext } from 'src/context/GlobalContext';
import DateSelector from 'src/components/DateSelector';
import CalenderDates from 'src/components/CalendarDates';
import { getScrollbarWidth } from 'src/utils/functions';

const AppointmentListHeader = () => {

    const { primaryColor } = useThemeContext();
    const { isMobile, numAvailablePractitioners } = useGlobalContext();

    return (
        <Grid container sx={{paddingRight: numAvailablePractitioners > 3 ? `${getScrollbarWidth()}px` : 0,
            '& .MuiTextField-root': { width: '100%', backgroundColor: 'white', borderColor: 'red' },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: primaryColor,
                },
            },
            '& .MuiOutlinedInput-input': {
                fontSize: '14px',
                padding: '15px',
            },
            paddingTop: '10px'
        }}>
            <Grid item xs={12} md={6} sx={!isMobile ? {pr: 2} : {mb: 2}}>
                <DateSelector/>
            </Grid>
            {!isMobile && <Grid item xs={12} md={6}>
                <CalenderDates />
            </Grid>}
        </Grid> 
    )
}

export default AppointmentListHeader;