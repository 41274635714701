import React, { createContext, useContext, useState } from 'react';
import moment from 'moment';

export const AppointmentContext = createContext();

export default function AppointmentContextProvider({ children }) {
    const [startingDate, setStartingDate] = useState(moment());
    const [isExistingPatient, setIsExistingPatient] = useState(null);
    const [isNewConcern, setIsNewConcern] = useState(null);

    return (
        <AppointmentContext.Provider value={{ 
            startingDate, 
            setStartingDate,
            isExistingPatient,
            setIsExistingPatient,
            isNewConcern,
            setIsNewConcern
        }}>
            {children}
        </AppointmentContext.Provider>
    );
}

export function useAppointmentContext() {
    const context = useContext(AppointmentContext);
    if (!context) {
        throw new Error('useAppointment must be used within a AppointmentProvider');
    }
    return context;
}